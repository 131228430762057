<template>
    <section>
        <div class="row mx-0">
            <div class="col-12 pt-4 px-0">
                <tabla-general :data="clientes" :columnas="dataColumnas" usar-paginacion :alto="$route.name == 'admin.cedis.cupones.ver.clientes-especificos' ? 'calc(100vh - 410px)' : 'calc(100vh - 284px)'">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general text-center f-16 f-600">
                            {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }} que pueden hacer efectivo el cupón
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column label="Cupón aplicado" width="180" class-name="text-general f-16 text-center">
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto">
                                        <span class="text-general f-18">
                                            {{ row.padre.numerador }} / {{ row.padre.denominador }}
                                            <el-tooltip placement="bottom" content="Buscar clientes por cliterios" effect="light">
                                                <div slot="content">
                                                    <p class="mb-2">
                                                        Vigencia:{{ row.padre.fecha_vigencia | helper-fecha('DD MMM YYYY') }}
                                                    </p>
                                                    Aplicado: <p v-for="(f, idx) in row.padre.fechas_usos" :key="idx">
                                                        {{ f | helper-fecha('DD MMM YYYY') }}
                                                    </p>
                                                </div>
                                                <i v-if="row.padre.icono == 2" class="icon-cupon f-18 text-general2" />
                                                <i v-if="row.padre.icono == 3" class="icon-cupon f-18 text-general" />
                                                <i v-if="row.padre.icono == 1" class="icon-cupon f-18 text-general-red" />
                                            </el-tooltip>
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Fecha de uso" width="180" class-name="text-general f-16 text-center">
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <span class="text-general f-18">
                                        {{ row.padre.ultimo_uso | helper-fecha("DD-MMM-YYYY") }}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="datosBasicos.tipo == 2" label="Bono aplicado" width="180" class-name="text-general f-16 text-center">
                            <template slot-scope="{row}">
                                <div v-if="row.hijo.denominador != null" class="row mx-0 justify-center">
                                    <div class="col-auto">
                                        <span class="text-general f-18">
                                            {{ row.hijo.numerador }} / {{ row.hijo.denominador }}
                                            <el-tooltip placement="bottom" :content="`Buscar ${$config.cliente + 's'} por cliterios`" effect="light">
                                                <div slot="content">
                                                    <p class="mb-2">
                                                        Vigencia:{{ row.hijo.fecha_vigencia | helper-fecha('DD MMM YYYY') }}
                                                    </p>
                                                    Aplicado: <p v-for="(f, idx) in row.hijo.fechas_usos" :key="idx">
                                                        {{ f | helper-fecha('DD MMM YYYY') }}
                                                    </p>
                                                </div>
                                                <i v-if="row.hijo.icono == 2" class="icon-cupon f-18 text-general2" />
                                                <i v-if="row.hijo.icono == 3" class="icon-cupon f-18 text-general" />
                                                <i v-if="row.hijo.icono == 1" class="icon-cupon f-18 text-general-red" />
                                            </el-tooltip>
                                        </span>
                                    </div>
                                </div>
                                <div v-else class="row mx-0 justify-center">
                                    --
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="datosBasicos.tipo == 2" prop="fecha_uso" label="Fecha de uso" width="180" class-name="text-general f-16 text-center">
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <span class="text-general f-18">
                                        {{ row.hijo.ultimo_uso | helper-fecha("DD-MMM-YYYY") }}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
    </section>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            dataColumnas: [
                { valor: 'nombre', titulo:"Nombre", class: 'text-general f-16', ancho: 250 },
                { valor: 'cedis', titulo:"Cedis", class: 'text-general f-16' },
                { valor: 'lechero', titulo:"Leechero principal", class: 'text-general f-16', ancho: 230 },
            ],
            clientes:[],
            multiple:true,
        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
            cupon: 'cupones/cupones/datosDetalle'
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        }
    },
    created(){
        this.getDatosBasicos()
    },
    mounted(){
        this.dataColumnas[2].titulo = `${this.$config.vendedor} principal`
        this.listarClientes()
        this.changeTitle()
    },
    methods: {
        async getDatosBasicos(){
            try {
                await this.$store.dispatch('cupones/cupones/getDatosBasicos',this.id_cupon)
                // if(this.datosBasicos.aplica_usuarios === 2){
                //     this.multiple = true
                // }

            } catch (e){
                this.error_catch(e)
            }
        },
        changeTitle(){
            //console.log('esto se ejecuta', this.cupon);
            if(this.cupon.destinatario == 1){
                this.dataColumnas[2].titulo = `${this.$config.cliente} principal`
            }else if(this.cupon.destinatario == 2){
                this.dataColumnas[2].titulo = `${this.$config.vendedor} principal`
            }
        },
        async listarClientes(){
            try {
                const {data} = await Cupones.listarClientes(this.id_cupon)
                this.clientes = data.clientes
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>